@import '@popsure/dirty-swan/dist/colors';

.react-tel-input {
  font-family: Lato, sans-serif;
  font-size: 16px;
  position: relative;
  width: 100%;

  :disabled {
    cursor: not-allowed;
  }

  .flag {
    width: 32px;
    height: 24px;

    background-color: $ds-grey-200;
    border-radius: 2px;
  }

  .ad {
    background-image: url('./flags/ad.svg');
  }
  .ae {
    background-image: url('./flags/ae.svg');
  }
  .af {
    background-image: url('./flags/af.svg');
  }
  .ag {
    background-image: url('./flags/ag.svg');
  }
  .ai {
    background-image: url('./flags/ai.svg');
  }
  .al {
    background-image: url('./flags/al.svg');
  }
  .am {
    background-image: url('./flags/am.svg');
  }
  .ao {
    background-image: url('./flags/ao.svg');
  }
  .aq {
    background-image: url('./flags/aq.svg');
  }
  .ar {
    background-image: url('./flags/ar.svg');
  }
  .as {
    background-image: url('./flags/as.svg');
  }
  .at {
    background-image: url('./flags/at.svg');
  }
  .au {
    background-image: url('./flags/au.svg');
  }
  .aw {
    background-image: url('./flags/aw.svg');
  }
  .ax {
    background-image: url('./flags/ax.svg');
  }
  .az {
    background-image: url('./flags/az.svg');
  }
  .ba {
    background-image: url('./flags/ba.svg');
  }
  .bb {
    background-image: url('./flags/bb.svg');
  }
  .bd {
    background-image: url('./flags/bd.svg');
  }
  .be {
    background-image: url('./flags/be.svg');
  }
  .bf {
    background-image: url('./flags/bf.svg');
  }
  .bg {
    background-image: url('./flags/bg.svg');
  }
  .bh {
    background-image: url('./flags/bh.svg');
  }
  .bi {
    background-image: url('./flags/bi.svg');
  }
  .bj {
    background-image: url('./flags/bj.svg');
  }
  .bl {
    background-image: url('./flags/bl.svg');
  }
  .bm {
    background-image: url('./flags/bm.svg');
  }
  .bn {
    background-image: url('./flags/bn.svg');
  }
  .bo {
    background-image: url('./flags/bo.svg');
  }
  .bq {
    background-image: url('./flags/bq.svg');
  }
  .br {
    background-image: url('./flags/br.svg');
  }
  .bs {
    background-image: url('./flags/bs.svg');
  }
  .bt {
    background-image: url('./flags/bt.svg');
  }
  .bv {
    background-image: url('./flags/bv.svg');
  }
  .bw {
    background-image: url('./flags/bw.svg');
  }
  .by {
    background-image: url('./flags/by.svg');
  }
  .bz {
    background-image: url('./flags/bz.svg');
  }
  .ca {
    background-image: url('./flags/ca.svg');
  }
  .cc {
    background-image: url('./flags/cc.svg');
  }
  .cd {
    background-image: url('./flags/cd.svg');
  }
  .cf {
    background-image: url('./flags/cf.svg');
  }
  .cg {
    background-image: url('./flags/cg.svg');
  }
  .ch {
    background-image: url('./flags/ch.svg');
  }
  .ci {
    background-image: url('./flags/ci.svg');
  }
  .ck {
    background-image: url('./flags/ck.svg');
  }
  .cl {
    background-image: url('./flags/cl.svg');
  }
  .cm {
    background-image: url('./flags/cm.svg');
  }
  .cn {
    background-image: url('./flags/cn.svg');
  }
  .co {
    background-image: url('./flags/co.svg');
  }
  .cr {
    background-image: url('./flags/cr.svg');
  }
  .cu {
    background-image: url('./flags/cu.svg');
  }
  .cv {
    background-image: url('./flags/cv.svg');
  }
  .cw {
    background-image: url('./flags/cw.svg');
  }
  .cx {
    background-image: url('./flags/cx.svg');
  }
  .cy {
    background-image: url('./flags/cy.svg');
  }
  .cz {
    background-image: url('./flags/cz.svg');
  }
  .de {
    background-image: url('./flags/de.svg');
  }
  .dj {
    background-image: url('./flags/dj.svg');
  }
  .dk {
    background-image: url('./flags/dk.svg');
  }
  .dm {
    background-image: url('./flags/dm.svg');
  }
  .do {
    background-image: url('./flags/do.svg');
  }
  .dz {
    background-image: url('./flags/dz.svg');
  }
  .ec {
    background-image: url('./flags/ec.svg');
  }
  .ee {
    background-image: url('./flags/ee.svg');
  }
  .eg {
    background-image: url('./flags/eg.svg');
  }
  .eh {
    background-image: url('./flags/eh.svg');
  }
  .er {
    background-image: url('./flags/er.svg');
  }
  .es-ca {
    background-image: url('./flags/es-ca.svg');
  }
  .es-ga {
    background-image: url('./flags/es-ga.svg');
  }
  .es {
    background-image: url('./flags/es.svg');
  }
  .et {
    background-image: url('./flags/et.svg');
  }
  .eu {
    background-image: url('./flags/eu.svg');
  }
  .fi {
    background-image: url('./flags/fi.svg');
  }
  .fj {
    background-image: url('./flags/fj.svg');
  }
  .fk {
    background-image: url('./flags/fk.svg');
  }
  .fm {
    background-image: url('./flags/fm.svg');
  }
  .fo {
    background-image: url('./flags/fo.svg');
  }
  .fr {
    background-image: url('./flags/fr.svg');
  }
  .ga {
    background-image: url('./flags/ga.svg');
  }
  .gb-eng {
    background-image: url('./flags/gb-eng.svg');
  }
  .gb-nir {
    background-image: url('./flags/gb-nir.svg');
  }
  .gb-sct {
    background-image: url('./flags/gb-sct.svg');
  }
  .gb-wls {
    background-image: url('./flags/gb-wls.svg');
  }
  .gb {
    background-image: url('./flags/gb.svg');
  }
  .gd {
    background-image: url('./flags/gd.svg');
  }
  .ge {
    background-image: url('./flags/ge.svg');
  }
  .gf {
    background-image: url('./flags/gf.svg');
  }
  .gg {
    background-image: url('./flags/gg.svg');
  }
  .gh {
    background-image: url('./flags/gh.svg');
  }
  .gi {
    background-image: url('./flags/gi.svg');
  }
  .gl {
    background-image: url('./flags/gl.svg');
  }
  .gm {
    background-image: url('./flags/gm.svg');
  }
  .gn {
    background-image: url('./flags/gn.svg');
  }
  .gp {
    background-image: url('./flags/gp.svg');
  }
  .gq {
    background-image: url('./flags/gq.svg');
  }
  .gr {
    background-image: url('./flags/gr.svg');
  }
  .gs {
    background-image: url('./flags/gs.svg');
  }
  .gt {
    background-image: url('./flags/gt.svg');
  }
  .gu {
    background-image: url('./flags/gu.svg');
  }
  .gw {
    background-image: url('./flags/gw.svg');
  }
  .gy {
    background-image: url('./flags/gy.svg');
  }
  .hk {
    background-image: url('./flags/hk.svg');
  }
  .hm {
    background-image: url('./flags/hm.svg');
  }
  .hn {
    background-image: url('./flags/hn.svg');
  }
  .hr {
    background-image: url('./flags/hr.svg');
  }
  .ht {
    background-image: url('./flags/ht.svg');
  }
  .hu {
    background-image: url('./flags/hu.svg');
  }
  .id {
    background-image: url('./flags/id.svg');
  }
  .ie {
    background-image: url('./flags/ie.svg');
  }
  .il {
    background-image: url('./flags/il.svg');
  }
  .im {
    background-image: url('./flags/im.svg');
  }
  .in {
    background-image: url('./flags/in.svg');
  }
  .io {
    background-image: url('./flags/io.svg');
  }
  .iq {
    background-image: url('./flags/iq.svg');
  }
  .ir {
    background-image: url('./flags/ir.svg');
  }
  .is {
    background-image: url('./flags/is.svg');
  }
  .it {
    background-image: url('./flags/it.svg');
  }
  .je {
    background-image: url('./flags/je.svg');
  }
  .jm {
    background-image: url('./flags/jm.svg');
  }
  .jo {
    background-image: url('./flags/jo.svg');
  }
  .jp {
    background-image: url('./flags/jp.svg');
  }
  .ke {
    background-image: url('./flags/ke.svg');
  }
  .kg {
    background-image: url('./flags/kg.svg');
  }
  .kh {
    background-image: url('./flags/kh.svg');
  }
  .ki {
    background-image: url('./flags/ki.svg');
  }
  .km {
    background-image: url('./flags/km.svg');
  }
  .kn {
    background-image: url('./flags/kn.svg');
  }
  .kp {
    background-image: url('./flags/kp.svg');
  }
  .kr {
    background-image: url('./flags/kr.svg');
  }
  .kw {
    background-image: url('./flags/kw.svg');
  }
  .ky {
    background-image: url('./flags/ky.svg');
  }
  .kz {
    background-image: url('./flags/kz.svg');
  }
  .la {
    background-image: url('./flags/la.svg');
  }
  .lb {
    background-image: url('./flags/lb.svg');
  }
  .lc {
    background-image: url('./flags/lc.svg');
  }
  .li {
    background-image: url('./flags/li.svg');
  }
  .lk {
    background-image: url('./flags/lk.svg');
  }
  .lr {
    background-image: url('./flags/lr.svg');
  }
  .ls {
    background-image: url('./flags/ls.svg');
  }
  .lt {
    background-image: url('./flags/lt.svg');
  }
  .lu {
    background-image: url('./flags/lu.svg');
  }
  .lv {
    background-image: url('./flags/lv.svg');
  }
  .ly {
    background-image: url('./flags/ly.svg');
  }
  .ma {
    background-image: url('./flags/ma.svg');
  }
  .mc {
    background-image: url('./flags/mc.svg');
  }
  .md {
    background-image: url('./flags/md.svg');
  }
  .me {
    background-image: url('./flags/me.svg');
  }
  .mf {
    background-image: url('./flags/mf.svg');
  }
  .mg {
    background-image: url('./flags/mg.svg');
  }
  .mh {
    background-image: url('./flags/mh.svg');
  }
  .mk {
    background-image: url('./flags/mk.svg');
  }
  .ml {
    background-image: url('./flags/ml.svg');
  }
  .mm {
    background-image: url('./flags/mm.svg');
  }
  .mn {
    background-image: url('./flags/mn.svg');
  }
  .mo {
    background-image: url('./flags/mo.svg');
  }
  .mp {
    background-image: url('./flags/mp.svg');
  }
  .mq {
    background-image: url('./flags/mq.svg');
  }
  .mr {
    background-image: url('./flags/mr.svg');
  }
  .ms {
    background-image: url('./flags/ms.svg');
  }
  .mt {
    background-image: url('./flags/mt.svg');
  }
  .mu {
    background-image: url('./flags/mu.svg');
  }
  .mv {
    background-image: url('./flags/mv.svg');
  }
  .mw {
    background-image: url('./flags/mw.svg');
  }
  .mx {
    background-image: url('./flags/mx.svg');
  }
  .my {
    background-image: url('./flags/my.svg');
  }
  .mz {
    background-image: url('./flags/mz.svg');
  }
  .na {
    background-image: url('./flags/na.svg');
  }
  .nc {
    background-image: url('./flags/nc.svg');
  }
  .ne {
    background-image: url('./flags/ne.svg');
  }
  .nf {
    background-image: url('./flags/nf.svg');
  }
  .ng {
    background-image: url('./flags/ng.svg');
  }
  .ni {
    background-image: url('./flags/ni.svg');
  }
  .nl {
    background-image: url('./flags/nl.svg');
  }
  .no {
    background-image: url('./flags/no.svg');
  }
  .np {
    background-image: url('./flags/np.svg');
  }
  .nr {
    background-image: url('./flags/nr.svg');
  }
  .nu {
    background-image: url('./flags/nu.svg');
  }
  .nz {
    background-image: url('./flags/nz.svg');
  }
  .om {
    background-image: url('./flags/om.svg');
  }
  .pa {
    background-image: url('./flags/pa.svg');
  }
  .pe {
    background-image: url('./flags/pe.svg');
  }
  .pf {
    background-image: url('./flags/pf.svg');
  }
  .pg {
    background-image: url('./flags/pg.svg');
  }
  .ph {
    background-image: url('./flags/ph.svg');
  }
  .pk {
    background-image: url('./flags/pk.svg');
  }
  .pl {
    background-image: url('./flags/pl.svg');
  }
  .pm {
    background-image: url('./flags/pm.svg');
  }
  .pn {
    background-image: url('./flags/pn.svg');
  }
  .pr {
    background-image: url('./flags/pr.svg');
  }
  .ps {
    background-image: url('./flags/ps.svg');
  }
  .pt {
    background-image: url('./flags/pt.svg');
  }
  .pw {
    background-image: url('./flags/pw.svg');
  }
  .py {
    background-image: url('./flags/py.svg');
  }
  .qa {
    background-image: url('./flags/qa.svg');
  }
  .re {
    background-image: url('./flags/re.svg');
  }
  .ro {
    background-image: url('./flags/ro.svg');
  }
  .rs {
    background-image: url('./flags/rs.svg');
  }
  .ru {
    background-image: url('./flags/ru.svg');
  }
  .rw {
    background-image: url('./flags/rw.svg');
  }
  .sa {
    background-image: url('./flags/sa.svg');
  }
  .sb {
    background-image: url('./flags/sb.svg');
  }
  .sc {
    background-image: url('./flags/sc.svg');
  }
  .sd {
    background-image: url('./flags/sd.svg');
  }
  .se {
    background-image: url('./flags/se.svg');
  }
  .sg {
    background-image: url('./flags/sg.svg');
  }
  .sh {
    background-image: url('./flags/sh.svg');
  }
  .si {
    background-image: url('./flags/si.svg');
  }
  .sj {
    background-image: url('./flags/sj.svg');
  }
  .sk {
    background-image: url('./flags/sk.svg');
  }
  .sl {
    background-image: url('./flags/sl.svg');
  }
  .sm {
    background-image: url('./flags/sm.svg');
  }
  .sn {
    background-image: url('./flags/sn.svg');
  }
  .so {
    background-image: url('./flags/so.svg');
  }
  .sr {
    background-image: url('./flags/sr.svg');
  }
  .ss {
    background-image: url('./flags/ss.svg');
  }
  .st {
    background-image: url('./flags/st.svg');
  }
  .sv {
    background-image: url('./flags/sv.svg');
  }
  .sx {
    background-image: url('./flags/sx.svg');
  }
  .sy {
    background-image: url('./flags/sy.svg');
  }
  .sz {
    background-image: url('./flags/sz.svg');
  }
  .tc {
    background-image: url('./flags/tc.svg');
  }
  .td {
    background-image: url('./flags/td.svg');
  }
  .tf {
    background-image: url('./flags/tf.svg');
  }
  .tg {
    background-image: url('./flags/tg.svg');
  }
  .th {
    background-image: url('./flags/th.svg');
  }
  .tj {
    background-image: url('./flags/tj.svg');
  }
  .tk {
    background-image: url('./flags/tk.svg');
  }
  .tl {
    background-image: url('./flags/tl.svg');
  }
  .tm {
    background-image: url('./flags/tm.svg');
  }
  .tn {
    background-image: url('./flags/tn.svg');
  }
  .to {
    background-image: url('./flags/to.svg');
  }
  .tr {
    background-image: url('./flags/tr.svg');
  }
  .tt {
    background-image: url('./flags/tt.svg');
  }
  .tv {
    background-image: url('./flags/tv.svg');
  }
  .tw {
    background-image: url('./flags/tw.svg');
  }
  .tz {
    background-image: url('./flags/tz.svg');
  }
  .ua {
    background-image: url('./flags/ua.svg');
  }
  .ug {
    background-image: url('./flags/ug.svg');
  }
  .um {
    background-image: url('./flags/um.svg');
  }
  .un {
    background-image: url('./flags/un.svg');
  }
  .us {
    background-image: url('./flags/us.svg');
  }
  .uy {
    background-image: url('./flags/uy.svg');
  }
  .uz {
    background-image: url('./flags/uz.svg');
  }
  .va {
    background-image: url('./flags/va.svg');
  }
  .vc {
    background-image: url('./flags/vc.svg');
  }
  .ve {
    background-image: url('./flags/ve.svg');
  }
  .vg {
    background-image: url('./flags/vg.svg');
  }
  .vi {
    background-image: url('./flags/vi.svg');
  }
  .vn {
    background-image: url('./flags/vn.svg');
  }
  .vu {
    background-image: url('./flags/vu.svg');
  }
  .wf {
    background-image: url('./flags/wf.svg');
  }
  .ws {
    background-image: url('./flags/ws.svg');
  }
  .xk {
    background-image: url('./flags/xk.svg');
  }
  .ye {
    background-image: url('./flags/ye.svg');
  }
  .yt {
    background-image: url('./flags/yt.svg');
  }
  .za {
    background-image: url('./flags/za.svg');
  }
  .zm {
    background-image: url('./flags/zm.svg');
  }
  .zw {
    background-image: url('./flags/zw.svg');
  }
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .hide {
    display: none;
  }
  .v-hide {
    visibility: hidden;
  }
}
