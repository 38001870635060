@import '~@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.buttonContainer {
  column-count: 2;
  column-gap: 8px;

  width: 80%;

  button,
  a {
    flex: 1;
  }

  margin: 0 auto;

  a:last-child {
    background-color: $ds-primary-50;
  }

  @include p-size-mobile {
    flex-direction: column;
    row-gap: 8px;
  }
}

.buttonBox {
  position: fixed;
  bottom: 64px;
  right: 0;
  z-index: 10;
  max-width: 100%;
  overflow-wrap: break-word;
}
