@import '@popsure/dirty-swan/dist/colors';

.image {
  display: inline-block;
  content: '';
  width: 357px;
  height: 144px;

  max-width: 100%;
  background-color: $ds-primary-500;

  mask: url('./img/404.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
