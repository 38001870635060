@import '@popsure/dirty-swan/dist/grid';

.wrapper {
  z-index: 1; // 1 more than the actual widget
  position: fixed;
  display: block;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.0588235) 0px 1px 6px 0px,
  rgba(0, 0, 0, 0.156863) 0px 2px 32px 0px;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;

  @include p-size-desktop {
    top: auto;
    bottom: 24px;
    right: 24px;
    width: 60px;
    height: 60px;
  }
}

.button {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  transform-origin: center;
  overflow-x: hidden;
  overflow-y: hidden;
  backface-visibility: hidden;
  background: none;

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(1);
  }
}

.whiteSpinner {
  filter: opacity(0.9) brightness(3);
}
