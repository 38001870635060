@import '@popsure/dirty-swan/dist/colors';

@import './high-res-flags.scss';

.react-tel-input {
  .form-control {
    appearance: none;

    display: block;

    padding-left: 72px;
    font-size: 16px;
    line-height: 24px;

    height: 48px;
    width: 100%;

    border-radius: 8px;
    border: 1px solid $ds-grey-300;
    background-color: white;

    color: $ds-grey-900;
    font-family: Lato, sans-serif;

    &::placeholder {
      color: $ds-grey-300;
    }

    &:disabled {
      background-color: $ds-grey-300;
      border-color: $ds-grey-500;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $ds-primary-500;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      appearance: none;
    }
  }
  .flag-dropdown {
    position: absolute;

    top: 0;
    bottom: 0;
    padding: 0;

    &:hover,
    &:focus {
      cursor: pointer;
    }
    &.open {
      z-index: 2;
    }
  }
  input[disabled] {
    & + .flag-dropdown {
      &:hover {
        cursor: default;
        .selected-flag {
          background-color: transparent;
        }
      }
    }
  }
  .special-label {
    display: none;
  }
  .selected-flag {
    outline: none;
    position: relative;
    width: 56px;
    height: 100%;
    padding: 0 0 0 12px;

    .flag {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .arrow {
      position: relative;

      margin-top: -1px;

      top: 2px;
      left: 44px;

      width: 8px;
      height: 100%;

      background-image: url('./img/arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(180deg);

      &.up {
        transform: rotate(0deg);
      }

      transition: 0.15s transform;
    }
  }

  @keyframes appear-in {
    from {
      opacity: 0;
      transform: translateY(10px);
    }

    to {
      opacity: 1;
    }
  }

  .country-list {
    position: absolute;

    outline: none;

    z-index: 1;

    border: 1px solid $ds-grey-300;

    list-style: none;
    padding: 0;
    margin: 10px 0 10px -1px;
    background-color: white;

    width: 300px;

    max-height: 224px;

    overflow-y: scroll;
    border-radius: 8px;

    animation-name: appear-in;
    animation-duration: 0.3s;
    animation-fill-mode: both;

    .flag {
      display: inline-block;
      position: absolute;

      left: 10px;

      top: 12px;
    }

    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ccc;
    }

    .country {
      height: 48px;

      position: relative;

      .dial-code {
        color: $ds-grey-500;
        line-height: 48px;
      }

      &:hover {
        background-color: $ds-grey-200;
      }

      &.highlight {
        background-color: $ds-primary-100;
      }
    }

    .country-name {
      margin-right: 6px;
      margin-left: 64px;
    }

    .search {
      z-index: 2;
      position: sticky;
      top: 0;
      background-color: #fff;
      padding: 10px 0 6px 10px;
    }

    .search-emoji {
      font-size: 15px;
    }

    .search-box {
      border: 1px solid #cacaca;
      border-radius: 3px;
      font-size: 15px;
      line-height: 15px;
      margin-left: 6px;
      padding: 3px 8px 5px;
      outline: none;
    }

    .no-entries-message {
      padding: 7px 10px 11px;
      opacity: 0.7;
    }
  }
}
