.emoji {
  font-size: 36px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;

  margin-left: -8px;

  > * {
    margin-left: 8px;
  }
}
